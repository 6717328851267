/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'); */
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;

  /* font-optical-sizing: auto; */
  /* font-weight: 400; */
  /* font-style: normal; */
}

@layer base {
  /* HTML & Body */
  html {
    @apply text-base leading-relaxed antialiased;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    font-family: "Montserrat", sans-serif;
    background-color: white;
  }

  body {
    @apply text-gray-800 bg-gray-100;
    font-family: "Montserrat", sans-serif;
  }

  /* Resetting common elements */
  *,
  *::before,
  *::after {
    @apply box-border p-0 m-0;
  }

  img {
    @apply max-w-full h-auto block;
  }

  a {
    @apply no-underline text-primary;
    word-break: break-word;
  }

  button,
  input,
  select,
  textarea {
    font-family: inherit; /* Explicitly inheriting the font family */
    font-size: inherit; /* Explicitly inheriting the font size */
    line-height: inherit; /* Explicitly inheriting the line height */
    color: inherit; /* Explicitly inheriting the text color */
  }

  button {
    @apply cursor-pointer;
  }

  ul,
  ol {
    @apply list-none;
  }

  table {
    @apply w-full border-collapse;
  }

  th,
  td {
    @apply p-3 text-left border-b border-gray-300;
  }

  blockquote {
    @apply my-6 pl-4 border-l-4 border-gray-300 text-gray-600 italic;
  }

  hr {
    @apply border-0 border-t border-gray-300 my-6;
  }

  /* Headings */
  h1 {
    @apply text-4xl font-semibold mb-4;
  }

  h2 {
    @apply text-3xl font-semibold mb-4;
  }

  h3 {
    @apply text-2xl font-semibold mb-4;
  }

  h4 {
    @apply text-xl font-semibold mb-4;
  }

  h5 {
    @apply text-lg font-semibold mb-4;
  }

  h6 {
    @apply text-base font-semibold mb-4;
  }

  p {
    @apply mb-4;
  }

  code,
  pre {
    @apply font-mono bg-gray-200 p-1 rounded;
  }

  pre {
    @apply overflow-x-auto p-4;
  }

  code {
    @apply px-1;
  }

  pre code {
    @apply p-0 bg-transparent;
  }
}

@layer components {
  .carousel::-webkit-scrollbar {
    width: 0px;
  }
  .carousel {
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
  }
  .slide {
    scroll-snap-align: center;
  }
  .services {
    transform-style: preserve-3d;
    perspective: 1000px;
    /* background: repeating-radial-gradient(circle at 100% 100%, #aff645, #aff645 3px, #fff 5px, #fff 100px); */
    background-position: 100% 0%;
  }
  .details-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    margin-top: 20px auto;
  }

  .details-table thead {
    background-color: #f8f9fa;
    text-align: left;
  }

  .details-table thead td {
    padding: 12px 15px;
    font-weight: bold;
    border-bottom: 2px solid #dee2e6;
  }

  .details-table tbody tr {
    border-bottom: 1px solid #dee2e6;
  }

  .details-table tbody td {
    padding: 10px 15px;
    color: #333;
    text-wrap: wrap;
    text-overflow: ellipsis;
  }

  .details-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .details-table tbody tr:hover {
    background-color: #e9ecef;
  }

  .details-table td:first-child {
    width: 30%;
    font-weight: bold;
    color: #0056b3;
  }

  .details-table td:last-child {
    width: 70%;
  }
}

@layer utilities {
  .not-last:not(:last-of-type) {
    @apply border-r-primary;
  }
  .scollbar::-webkit-scrollbar {
    display: none;
    /* border-radius: 10px;
background-color: blue; */
  }
  .intelligent{
    
  }
  .loader {
    font-weight: bold;
    font-family: sans-serif;
    font-size: 16px;
    animation: l1 1s linear infinite alternate;
  }
  .loader:before {
    content: "Loading...";
  }
  .loader.submit::before{
    content: "Submiting...";
  }
  @keyframes l1 {
    to {
      opacity: 0;
    }
  }
}
